<template>
  <div class="share__donation">
    <button
      type="button"
      class="btn btn-light btn__share"
      data-bs-toggle="modal"
      data-bs-target="#shareModal"
    >
      <i class="icon__share" />
    </button>
    <router-link
      type="button"
      class="btn btn__donation"
      :class="{disabled : status === 'fullfunded' || checkTargetDate}"
      :to="{ path: `/transaksi/${uuid}`, query: { campaign: $route.params.slug} }"
    >
      {{status === 'fullfunded' ? 'Donasi Ditutup' : 'Donasi Sekarang'}}
    </router-link>
  </div>
  <div class="modal modal__share" tabindex="-1" id="shareModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal__content-share">
        <div class="modal-header modal__header-share">
          <h5 class="modal-title modal__title-share">Bagikan</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="modal__body-wrapper">
            <div class="icon__wraperr">
              <div class="box__icon-share">
                <a
                  :href="`https://api.whatsapp.com/send?text=${documentUrl}-${copywriter}`"
                  target="_blank"
                >
                  <img
                    :src="require('../../assets/svg/whatsapp.svg')"
                    alt="Icon Share"
                    class="icon__share"
                  />
                </a>
              </div>
              <label for="whatsapp" class="icon__label">Whatsapp</label>
            </div>
            <div class="icon__wrapper">
              <div class="box__icon-share">
                <a
                  :href="
                    `http://twitter.com/share?url=${encodeURIComponent(
                      documentUrl
                    )}&text=${encodeURIComponent(copywriter)}`
                  "
                  target="_blank"
                >
                  <img
                    :src="require('../../assets/svg/twitter.svg')"
                    alt="Icon Share"
                    class="icon__share"
                  />
                </a>
              </div>
              <label for="twitter" class="icon__label">Twitter</label>
            </div>
            <div class="icon__wrapper">
              <div class="box__icon-share">
                <a
                  :href="
                    `https://www.facebook.com/sharer/sharer.php?u=${documentUrl} - ${copywriter}`
                  "
                  target="_blank"
                >
                  <img
                    :src="require('../../assets/svg/facebook.svg')"
                    alt="Icon Share"
                    class="icon__share"
                  />
                </a>
              </div>
              <label for="facebook" class="icon__label">Facebook</label>
            </div>
            <div class="icon__wrapper">
              <div class="box__icon-share">
                <a :href="`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${copywriter}&body=${documentUrl}&ui=2&tf=1&pli=1`" target="_blank">
                  <img
                    :src="require('../../assets/svg/gmail.svg')"
                    alt="Icon Share"
                    class="icon__share"
                  />
                </a>
              </div>
              <label for="email" class="icon__label">Email</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="modal__footer-share">
            <label for="shareLink" class="label__share-link">Bagikan Link</label>
            <div class="link__share">
              <input type="text" class="form-control" ref="inputLink" :value="fullUrl" disabled />
              <!-- <span class="fa__icon" @click="handleCopyValue(fullUrl)">Salin</span> -->
              <p id="text_element" hidden>
                {{ fullUrl }}
              </p>
              <i class="fas fa-copy fa__icon" @click="handleCopyValue('text_element')" />
              <!-- <span @click="handleCopyValue(fullUrl)">Salin</span> -->
              <div id="tooltip-value" class="tooltip">
                Tersalin
              </div>
              <div id="tooltip-value" class="tooltip">
                Tersalin
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: { type: String, default: null },
    uuid: { type: String, default: null },
    copywriter: { type: String, default: null },
    status: { type: String, default: ''},
    targetDate: { type:String, default: null}
  },
  data() {
    return {
      hostName: window.location.hostname,
      fullUrl: window.location.href,
      pathName: window.location.pathname,
      documentUrl: document.URL,
      appId: process.env.VUE_APP_API_FB_APP_ID
    }
  },
  computed: {
    checkTargetDate() {
      if (this.$moment(new Date()).format('YYYY-MM-DD') > this.targetDate) {
        return true
      }

      return false
    }
  },
  methods: {
    handleCopyValue(elementId) {
      const aux = document.createElement('input')

      // Get the text from the element passed into the input
      aux.setAttribute('value', document.getElementById(elementId).innerHTML)

      // Append the aux input to the body
      document.body.appendChild(aux)

      // Highlight the content
      aux.select()

      // Execute the copy command
      document.execCommand('copy')

      // Remove the input from the body
      document.body.removeChild(aux)
      const tooltip = document.getElementById('tooltip-value')
      tooltip.classList.toggle('active')
      setTimeout(() => {
        tooltip.classList.remove('active')
      }, 1500)
    }
  }
}
</script>
