<template>
    <div id="berita-terbaru" class="p-3 mt-5">
        <div class="news__update-wrapper">
            <div class="d-flex mb-3" v-for="n in count" :key="n">
                <Skeleton width="32px" height="32" style="margin-right: 10px; border-radius:50%;"/>
                <Skeleton width="100" height="16"/>
            </div>
        </div>
    </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'
export default {
    components: { Skeleton },
    props: {
        count: { type: Number, default: 1 }
    }
}
</script>

<style lang="scss" scopoed>

</style>