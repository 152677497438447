<template>
  <div class="banner__category-wrapper">
    <Skeleton width="100" height="414" />
  </div>
  <div class="card card__donation">
    <div class="card-body">
      <Skeleton width="117px" height="30"/>
      <Skeleton width="100" height="20" style="margin-top:1rem;" />
      <Skeleton width="75" height="20" style="margin-top:0.5rem;" />
      <div class="progress progress__donation">
        <Skeleton width="100" height="12" />
      </div>
      <div class="donation__collected">
        <Skeleton width="85px" height="14" />
        <Skeleton width="143px" height="25" style="margin-top:0.5rem;" />
      </div>
      <div class="donation__target">
        <Skeleton width="35px" height="14" />
        <Skeleton width="141px" height="25" style="margin-top:0.5rem;" />
      </div>
      <div class="donation__donor">
        <Skeleton width="44px" height="14" style="margin-bottom:0.5rem;" />
        <div class="donation__list-donor">
          <div class="list__wrapper-donor" v-for="n in 5" :key="n">
            <Skeleton width="48px" height="48px" style="border-radius:50%;"/>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="card-text card__content">
        <Skeleton width="80" height="14" />
        <Skeleton width="100" height="14" style="margin-top:.5rem;" />
        <Skeleton width="80" height="14" style="margin-top:.5rem;" />
        <Skeleton width="80" height="14" style="margin-top:.5rem;" />
        <Skeleton width="70" height="14" style="margin-top:.5rem;" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'

export default {
  components: { Skeleton }
}
</script>
