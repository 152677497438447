<template>
  <LoaderDetailCampaign v-if="loading" />
  <div v-else>
    <NavbarDetail />
    <div class="banner__category-wrapper">
      <img :src="detailCampaign.wakafImageCover" alt="Image Campaign" class="image__category" />
    </div>
    <div class="card card__donation">
      <div class="card-body">
        <ShareDonation
          :targetDate="detailCampaign.wakafTargetDate"
          :url="detailCampaign.wakafUrl"
          :uuid="detailCampaign.wakafUuid"
          :copywriter="detailCampaign.wakafCopywrite"
          :status="detailCampaign.wakafStatus"
        />
        <div class="d-flex">
           <span class="card__label">{{ categoryName }}</span>
           <div v-if="detailCampaign.wakafStatus === 'published'" class="due__date-wrapper">
             <img src="../assets/svg/icon-date.svg" alt="icon-date" class="icon__date" />
             <b>{{detailCampaign.wakafTargetValue}}</b>
           </div>
        </div>
        <h5 class="card-title">
          {{ detailCampaign.wakafName }}
        </h5>
        <div class="progress progress__donation">
          <div
            class="progress-bar progress__bar-donation"
            role="progressbar"
            :style="{ width: `${detailCampaign.wakafTargetPercent}%` }"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div class="donation__collected">
          <h6 class="donation__title-collected">
            Donasi Terkumpul
          </h6>
          <h6 class="donation__value-collected">
            Rp.
            {{
              $formatPrice(
                detailCampaign.wakafSummary ? detailCampaign.wakafSummary.wakafTotalTerhimpun : 0
              )
            }}
            <span class="donation__value-percen">({{ detailCampaign.wakafTargetPercent }}%)</span>
          </h6>
        </div>
        <div class="donation__target">
          <h6 class="donation__title-target">
            Target
          </h6>
          <h6 class="donation__value-target">
            Rp. {{ $formatPrice(detailCampaign.wakafTarget ? detailCampaign.wakafTarget : 0) }}
          </h6>
        </div>
        <div class="tab-campaign_wrapper">
          <div class="d-flex align-items-center">
            <ul class="d-flex p-0">
              <span class="tab-campaign_item d-inline-block p-3" :class="parameter === 'cerita-awal' || parameter === '' ? 'border-bottom-2' : ''" @click="handelScrollTo('cerita-awal')">
                <span :class="parameter === 'cerita-awal' || parameter === '' ? 'text-orange' : 'text-black'">
                  Cerita Awal
                </span>
              </span>
              <span class="tab-campaign_item d-inline-block p-3" :class="parameter === 'berita-terbaru'? 'border-bottom-2' : ''" @click="handelScrollTo('berita-terbaru')">
                <span :class="parameter === 'berita-terbaru'? 'text-orange' : 'text-black'">
                  Berita Terbaru
                </span>
              </span>
              <router-link :to="{path: `/donatur/${detailCampaign.wakafUrl}`,params: {wakafName: detailCampaign.wakafName}}" class="tab-campaign_item d-inline-block p-3 text-decoration-none" :class="parameter === 'donatur'? 'border-bottom-2' : ''" @click="handelScrollTo('donatur')">
                <span :class="parameter === 'donatur'? 'text-orange' : 'text-black'">
                  Donatur
                </span>
              </router-link>
            </ul>
          </div>
        </div>
        <div id="cerita-awal" class="card-footer">
          <div class="card-text card__content" v-html="detailCampaign.wakafDescription" />
          <button type="button" class="btn btn-warning btn-sm btn__readmore" @click="handleReadMore">
            Baca Selengkapnya
            <i class="fas fa-angle-down font-size-12" />
          </button>
          <button
            type="button"
            class="btn btn-warning btn-sm btn__moreconcise"
            @click="handleMoreConcise"
            hidden
          >
            Baca Lebih Ringkas
            <i class="fas fa-angle-up font-size-12" />
          </button>
        </div>
        <ListUpdate id="berita-terbaru" :wakafId="detailCampaign.wakafUuid" :status="detailCampaign.wakafStatus" />
      </div>
      
    </div>
  </div>
</template>

<script>
import { imageCdn, initGoogleAnalytics, initFacebookPixel } from '@/_helpers'
import NavbarDetail from '../components/DetailCampaign/NavbarDetail.vue'
import ShareDonation from '../components/DetailCampaign/ShareDonationNow.vue'
import ListUpdate from '../components/DetailCampaign/ListUpdate'
import LoaderDetailCampaign from '../components/Loader/LoaderDetailCampaign.vue'

export default {
  components: { NavbarDetail, ShareDonation, ListUpdate, LoaderDetailCampaign },
  data() {
    return {
      detailCampaign: {
        wakafUuid: null,
        wakafName: null,
        wakafImageCover: null,
        wakafDescription: null,
        wakafCopywrite: null,
        wakafTarget: null,
        wakafTargetValue: null,
        wakafTargetType: null,
        wakafTrackingCodes: null,
        wakafTargetDate: null,
        wakafUrl: null,
        wakafSummary: {
          wakafSummartUuid: null,
          wakafTotalTerhimpun: null,
          wakafTotalDonatur: null
        },
        wakafStatus: '',
        donors: []
      },
      categoryName: null,
      donorsToShow: 5,
      totalDonor: 0,
      loading: true,
      parameter: 'cerita-awal'
    }
  },
  computed: {
    limitDonor() {
      return this.detailCampaign.donors.slice(0, this.donorsToShow)
    },
    // parseURLParams() {
    //   const url = window.location.href
    //   const queryEnd   = url.indexOf("#") + 1 || url.length + 1,
    //   query = url.slice(queryEnd)
    //   return query;
    // }
  },
  mounted() {
    this.getDetailCampaign()
  },
  beforeUnmount () {
    if (this.detailCampaign.wakafTrackingCodes !== null && typeof this.detailCampaign.wakafTrackingCodes.ga !== 'undefined' && this.detailCampaign.wakafTrackingCodes.ga !== null) {
      window['ga-disable-' + this.detailCampaign.wakafTrackingCodes.ga] = true
    }
  },
  methods: {
    handelScrollTo(value) {
      this.parameter = value
      const element = document.getElementById(value)
      console.log(element);
      element.scrollIntoView();
    },
    getDetailCampaign() {
      const slugUrl = this.$route.params.slug

      this.$http.get(`${process.env.VUE_APP_API_URL}campaigns/${slugUrl}`).then(response => {
        const catId = response.data.cat_id
        this.getDetailCategory(catId)
        this.getDonor(response.data)
      })
    },
    getDonor(data) {
      const wakafId = data.wakaf_id

      const valueTarget = data.target_date ? this.$moment(data.target_date)
        .startOf('day')
        .fromNow() : ''
      // const splitString = valueTarget.split(' ')

      this.$http
        .get(`${process.env.VUE_APP_API_URL}campaigns/${wakafId}/donaturs?limit=5`)
        .then(response => {
          const dataDonors = response.data.data
          const donorArr = []
          if (dataDonors.length > 0) {
            dataDonors.forEach(value => {
              const objDonor = {
                transactionId: value.transaction_id,
                user: value.user
                  ? {
                      firstName: value.user.first_name,
                      lastName: value.user.last_name,
                      photoUrl: value.user.photo_url
                    }
                  : null
              }
              donorArr.push(objDonor)
            })
          }

          const totalTerhimpun = data.summary !== null ? data.summary.total_terhimpun : 0
          const percentWakaf = (totalTerhimpun / data.target) * 100
          const input = data.wakaf_description
          const output = input.replace(
            /(<\/?(?:a|p|img|strong|em|s|u|blockquote|pre|ol|ul|li|h2|h3|h4|h5|h6|iframe)[^>]*>)|<[^>]+>/gi,
            '$1'
          )
          const wakafDescription = document.createElement('div')
          wakafDescription.className = 'content__campaign'
          wakafDescription.innerHTML = output
          if (wakafDescription.getElementsByTagName('a')[0]) {
            const tagHyperlink = wakafDescription.getElementsByTagName('a')[0]
            tagHyperlink.style.removeProperty('font-size')
            tagHyperlink.style.color = '#F28F1D'
          }
          if (wakafDescription.getElementsByTagName('img')[0]) {
            const tagImg = wakafDescription.getElementsByTagName('img')[0]
            tagImg.style.width = '100%'
          }

          this.detailCampaign = {
            wakafTrackingCodes: null,
            wakafUuid: data.wakaf_id,
            wakafName: data.wakaf_name,
            wakafImageCover: imageCdn(data.wakaf_image_cover_url, 'campaign-detail'),
            wakafDescription: imageCdn(wakafDescription.outerHTML, 'other'),
            wakafTarget: data.target,
            wakafUrl: data.wakaf_url,
            wakafCopywrite: data.wakaf_copywrite,
            wakafTargetValue: valueTarget,
            wakafTargetDate: data.target_date,
            wakafSummary: {
              wakafSummartUuid: data.summary !== null ? data.summary.wakaf_summary_id : '0',
              wakafTotalTerhimpun: data.summary !== null ? data.summary.total_terhimpun : '0',
              wakafTotalDonatur: data.summary !== null ? data.summary.total_donatur : '0'
            },
            wakafTargetPercent: parseInt(percentWakaf),
            wakafStatus: data.wakaf_status,
            donors: donorArr
          }
          const totalDonatur = data.summary !== null ? data.summary.total_donatur : 0
          this.totalDonor = totalDonatur >= 5 ? totalDonatur - this.donorsToShow : totalDonatur
          this.loading = false
          // start single campaign tracking
          if (typeof data.tracking_codes !== 'undefined' && data.tracking_codes !== null) {
            this.detailCampaign.wakafTrackingCodes = data.tracking_codes
            // Google Analytics
            if (typeof this.detailCampaign.wakafTrackingCodes.ga !== 'undefined' && this.detailCampaign.wakafTrackingCodes.ga !== null) {
              initGoogleAnalytics().init().then(() => {
                window['ga-disable-' + this.detailCampaign.wakafTrackingCodes.ga] = false
                const trackingName = 'wakaf_' + data.wakaf_id.replace(/-/g, '_')
                initGoogleAnalytics().create(this.detailCampaign.wakafTrackingCodes.ga, trackingName)
                initGoogleAnalytics().pageViewEvent(trackingName)
              }).catch(err => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log(err)
                }
              })
            }
            // Facebook Pixels
            if (typeof this.detailCampaign.wakafTrackingCodes.pixel !== 'undefined' && this.detailCampaign.wakafTrackingCodes.pixel !== null) {
              initFacebookPixel().init().then(() => {
                if (this.$store.getters['trackingCodes/findOne']({type: 'pixel', id: this.detailCampaign.wakafTrackingCodes.pixel}) === null) {
                  this.$store.dispatch('trackingCodes/setCode', {type: 'pixel', id: this.detailCampaign.wakafTrackingCodes.pixel})
                  initFacebookPixel().create(this.detailCampaign.wakafTrackingCodes.pixel)
                }
                initFacebookPixel().pageViewEvent(this.detailCampaign.wakafTrackingCodes.pixel)
              }).catch(err => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log(err)
                }
              })
            }
          }
        })
    },
    getDetailCategory(id) {
      this.$store.dispatch('campaignCategories/fetchCategories').then(() => {
        const campaignCategory = this.$store.getters['campaignCategories/findOne']({
          'key': 'cat_id',
          'value': id
        })
        if (campaignCategory !== null) {
          this.categoryName = campaignCategory.cat_name
        }
      })
    },
    handleReadMore(event) {
      const btnMoreConcise = document.querySelector('.btn__moreconcise')
      btnMoreConcise.removeAttribute('hidden')
      event.target.setAttribute('hidden', true)
      const cardContent = document.querySelector('.card__content')
      cardContent.classList.add('more__card-content')
      cardContent.classList.toggle('expanded')
    },
    handleMoreConcise(event) {
      const btnReadMore = document.querySelector('.btn__readmore')
      btnReadMore.removeAttribute('hidden')
      event.target.setAttribute('hidden', true)
      const cardContent = document.querySelector('.card__content')
      cardContent.classList.remove('more__card-content')
      cardContent.classList.remove('expanded')
    }
  },
}
</script>
