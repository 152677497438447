<template>
  <LoaderListUpdate v-if="loading" :count="3"/>
  <div v-else id="berita-terbaru" class="p-3 mt-5">
    <div ref="wrapperUpdate" class="news__update-wrapper">
      <div class="content__news-update" :style="contentNewsUpdate">
        <div v-for="(update,index) in updates" class="list__news" :key="index">
          <span class="bullets__point" />
          <div class="list__news-wrapper">
            <h6 class="title__update">
              {{ update.wakafUpdateTitle }}
            </h6>
            <div class="content__update card__content" v-html="update.wakafUpdateDescritpion" />
          </div>
          <span class="content__readmore" @click="handleReadMoreUpdate(index, $event)">Selengkapnya</span>
        </div>
      </div>
    </div>
    <div v-if="updates.length > 0" class="text-center">
      <router-link
        class="btn btn-warning btn__donation-now w-100 mt-3"
        :to="{ path: `/transaksi/${uuid}`, query: { transactionStep: 'step-1' } }"
        hidden
        :class="{disabled : status === 'fullfunded'}"
      >
        Donasi Sekarang
      </router-link>

      <button class="btn btn-warning btn__see-update w-100 mt-3" @click="handleListUpdate">
        List Update <span class="icon-direction" />
      </button>
    </div>
  </div>
</template>

<script>
import { imageCdn } from '@/_helpers'
import LoaderListUpdate from '../Loader/LoaderListUpdate.vue'
import swal from 'sweetalert2'
export default {
  name: 'ListUpdate',
  components: {LoaderListUpdate},
  props: {
    wakafId: { type: String, default: null },
    status: { type: String, default: null }
  },
  data() {
    return {
      updates: [],
      updateCounter: null,
      loading:false
    }
  },
  computed: {
    contentNewsUpdate() {
      return {
        'counter-reset': `a ${this.updateCounter + 1}`
      }
    }
  },
  mounted() {
    this.getListUpdate()
  },
  methods: {
    getListUpdate() {
      this.loading = true
      this.$http
        .get(`${process.env.VUE_APP_API_URL}campaign-updates?wakaf_id=${this.wakafId}&order=created_at&ascending=0`)
        .then(response => {
          const datas = response.data.data

          datas.forEach(value => {
            const input = value.wakaf_update_description
            const output = input.replace(/(<\/?(?:a|p|img)[^>]*>)|<[^>]+>/gi, '$1')
            const wakafUpdateDescritpion = document.createElement('div')
            wakafUpdateDescritpion.className = 'content__campaign'
            wakafUpdateDescritpion.innerHTML = output
            if (wakafUpdateDescritpion.getElementsByTagName('a')[0]) {
              const tagHyperlink = wakafUpdateDescritpion.getElementsByTagName('a')[0]
              tagHyperlink.style.removeProperty('font-size')
              tagHyperlink.style.color = '#F28F1D'
            }
            if (wakafUpdateDescritpion.getElementsByTagName('img')[0]) {
              const tagImg = wakafUpdateDescritpion.getElementsByTagName('img')[0]
              // tagImg.style.width = '100%'
            }

            const dataObj = {
              wakafId: value.wakaf_id,
              wakafUpdateId: value.wakaf_update_id,
              wakafUpdateTitle: value.wakaf_update_title,
              wakafUpdateDescritpion: imageCdn(wakafUpdateDescritpion.outerHTML, 'other')
            }

            this.updates.push(dataObj)
            this.updateCounter = this.updates.length
          })
          this.loading = false
        }).catch(error => {
          this.loading = false
          swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error
            })
        })
    },
    handleListUpdate(event) {
      const btnDonation = document.querySelector('.btn__donation-now')
      btnDonation.removeAttribute('hidden')
      event.target.setAttribute('hidden', true)
      const wrapperUpdate = document.querySelector('.news__update-wrapper')
      wrapperUpdate.classList.add('without__after-update')
      wrapperUpdate.classList.toggle('expanded')
      // $event.hidden = true
    },
    handleReadMoreUpdate(index, e) {
      const listNewsWrapper = document.querySelectorAll('.list__news-wrapper')
      listNewsWrapper[index].classList.add('news__update')
      listNewsWrapper[index].classList.toggle('expanded')
      e.target.setAttribute('hidden', true)
    }
  }
}
</script>
